import { email, required, requiredIf, isNotDuplicateEmail } from "@/validation/validators";

export const getGeneralSettingsSchema = (settings) => ({
    localGeneralSettings: {
        notificationSettings: {
            $each: {
                email: {
                    email,
                    required,
                },
            },
        },
        productReviewImageUploadEnabled: { required },
        companyResponseNotificationEnabled: { required },
        displayName: {
            required: requiredIf(() => settings.useDisplayName),
        },
    },
});

export const getNewEmailSchema = (settings) => ({
    userEmailInput: {
        email,
        isNotDuplicateEmail: isNotDuplicateEmail(settings),
    },
});
